@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: CoHeadline;
    src: local(CoHeadline-Regular),
    url(../fonts/CoHeadline-Regular.woff2) format("woff2"),
    url(../fonts/CoHeadline-Regular.woff) format("woff"),
    url(../fonts/CoHeadline-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Regular),
    url(../fonts/NotoSans-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Medium),
    url(../fonts/NotoSans-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: NotoSans;
    src: local(NotoSans-Bold),
    url(../fonts/NotoSans-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  html, body, #root {
    @apply h-full w-full;
  }

  body {
    @apply bg-[#FFFCF6] text-primary-text;
  }
}

@layer components {
  h1 {
    @apply text-h1 font-sans-co;
  }

  h2 {
    @apply text-h2 font-sans-co;
  }

  h3 {
    @apply text-h3 font-sans-co;
  }

  h4 {
    @apply text-h4;
  }

  p {
    @apply text-p1;
  }

  a,
  a:active,
  a:visited {
    @apply text-link-blue decoration-0;
  }

  a:hover {
    @apply underline;
  }

  .button,
  button,
  button[type=submit],
  input[type=button],
  input[type=submit] {
    @apply font-sans-co text-pure-white bg-dark-grey cursor-pointer border-solid border border-dark-grey font-medium rounded-full py-sm px-md w-full max-w-xs;
  }

  button:disabled,
  input[type=button]:disabled,
  button[type=submit]:disabled,
  input[type=button]:disabled,
  input[type=submit]:disabled
  {
    @apply text-light-grey bg-mid-grey;
  }

  .button.secondary,
  button.secondary,
  button[type=submit].secondary,
  input[type=button].secondary,
  input[type=submit].secondary {
    @apply bg-super-light-grey border-solid border border-primary-text rounded-full text-primary-text;
  }

  button.secondary:disabled,
  button[type=submit].secondary:disabled,
  input[type=button].secondary:disabled,
  input[type=submit].secondary:disabled {
    @apply text-light-grey border-super-light-grey;
  }

  @media screen(sm) {
    button,
    button[type=submit],
    input[type=button],
    input[type=submit] {
      @apply w-[12.5rem];
    }
  }
}